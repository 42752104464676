import i18n from './localization'

const WEBSITE_URL = 'https://brandsandninjas.com/'
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL


export default {
  REACT_APP_API_BASE_URL,
  WEBSITE_URL,
}

// ##################################
// ##
// ##     WIZARD CONSTANTS
// ##

const MAX_CHARS_MISSION_DESCRIPTION = 500
const NUMBER_OF_STORES_PER_PAGE = 50

const BINARY_ANSWERS = {
  no: { id: 'no', label: i18n.t('Common:No') },
  yes: { id: 'yes', label: i18n.t('Common:Yes') }
} as const
export type BinaryAnswerType = typeof BINARY_ANSWERS[keyof typeof BINARY_ANSWERS]

const QUESTION_TYPES = {
  binary: {
    id: 'binary',
    label: i18n.t('MissionWizard:QuestionTypeBinary'),
    hasOptions: false,
    expectedAnswerEnabled: true,
    defaultExpectedAnswer: BINARY_ANSWERS.no.id,
    validationEnabled: true
  },
  numeric: {
    id: 'numeric',
    label: i18n.t('MissionWizard:QuestionTypeNumeric'),
    hasOptions: false,
    expectedAnswerEnabled: true,
    defaultExpectedAnswer: '0',
    validationEnabled: false
  },
  free_text: {
    id: 'free_text',
    label: i18n.t('MissionWizard:QuestionTypeFreeText'),
    hasOptions: false,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  },
  multiple_choice: {
    id: 'multiple_choice',
    label: i18n.t('MissionWizard:QuestionTypeMultipleChoice'),
    hasOptions: true,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  },
  multiple_answer: {
    id: 'multiple_answer',
    label: i18n.t('MissionWizard:QuestionTypeMultipleAnswer'),
    hasOptions: true,
    expectedAnswerEnabled: false,
    defaultExpectedAnswer: null,
    validationEnabled: false
  }
} as const
export type QuestionTypesType = typeof QUESTION_TYPES[keyof typeof QUESTION_TYPES]

const DEFAULT_ANSWER_OPTIONS = [{order: 0, text: ''}, {order: 1, text: ''}]

const DEFAULT_NEW_QUESTION = () => ({
  order: 0,
  text: '',
  shortName: '',
  questionType: QUESTION_TYPES.binary.id,
  expectedAnswer: null,
  image: null,
  requiresPhotoValidation: false,
  answerOptions: JSON.parse(JSON.stringify(DEFAULT_ANSWER_OPTIONS)),
  toValidate: false
})


export const WizardConsts = {
  BINARY_ANSWERS,
  DEFAULT_NEW_QUESTION,
  MAX_CHARS_MISSION_DESCRIPTION,
  NUMBER_OF_STORES_PER_PAGE,
  QUESTION_TYPES
}


// ##################################
// ##
// ##    ANSWERS CONSTANTS
// ##

const ANSWERS_STATUSES = {
  all: {
    id: 'all',
    label: i18n.t('Answers:Status:All')
  },
  to_validate: {
    id: 'to_validate',
    label: i18n.t('Answers:Status:ToValidate')
  },
  accepted: {
    id: 'accepted',
    label: i18n.t('Answers:Status:Accepted')
  },
  denied: {
    id: 'denied',
    label: i18n.t('Answers:Status:Denied')
  }
} as const
export type AnswersStatusesType = typeof ANSWERS_STATUSES[keyof typeof ANSWERS_STATUSES]

const COMPLETED_ERRORS = {
  submitted_after_mission_end: {
    id: 'submitted_after_mission_end',
    label: i18n.t('Answers:CompletedError:SubmittedAfterMissionEnd')
  },
  answered_after_mission_end: {
    id: 'answered_after_mission_end',
    label: i18n.t('Answers:CompletedError:AnsweredAfterMissionEnd')
  },
  outside_geofence: {
    id: 'outside_geofence',
    label: i18n.t('Answers:CompletedError:OutsideGeofence')
  }
} as const

const CANT_DO_ERRORS = {
  closed_store: {
    id: 'closed_store',
    label: i18n.t('Answers:CantDoError:ClosedStore'),
    text_prop: null
  },
  access_denied: {
    id: 'access_denied',
    label: i18n.t('Answers:CantDoError:AccessDenied'),
    text_prop: null
  },
  def_closed_store: {
    id: 'def_closed_store',
    label: i18n.t('Answers:CantDoError:DefClosedStore'),
    text_prop: null
  },
  temp_closed_store: {
    id: 'temp_closed_store',
    label: i18n.t('Answers:CantDoError:TempClosedStore'),
    text_prop: null
  },
  non_horeca_submission_error: {
    id: 'non_horeca_submission_error',
    label: i18n.t('Answers:CantDoError:NonHorecaSubmissionError'),
    text_prop: 'nonHorecaSubmissionError'
  },
  other_error: {
    id: 'other_error',
    label: i18n.t('Answers:CantDoError:OtherError'),
    text_prop: 'userReportedError'
  }
} as const


export const AnswersConsts = {
  ANSWERS_STATUSES,
  CANT_DO_ERRORS,
  COMPLETED_ERRORS
}
