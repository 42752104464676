import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { createMission, getMissions, missionState } from '../../api'
import { MissionTypeAPI } from '../../api/types'
import { MissionCard } from '../../components/MissionCard'
import { Loading } from '../../components/shared/Loading'
import { useUser } from '../../contexts/User'
import { Button } from '../../elements/Button'
import { PageIndicator } from '../../elements/PageIndicator'
import { TabIndicator } from '../../elements/TabIndicator'
import { getPaths } from '../../routing/routes'
import useSearchParams from '../../utils/useSearchParams'

import styles from './MissionsPage.module.scss'


type MissionsPageProps = {
  state: missionState
}


export const MissionsPage = ({state}: MissionsPageProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()
  const { searchParams } = useSearchParams()
  const { hasPermissions } = useUser()

  const [loading, setLoading] = useState(true)
  const [missions, setMissions] = useState<MissionTypeAPI[]>([])
  const [lastPage, setLastPage] = useState(1)


  // Fetch missions
  useEffect(() => {
    setLoading(true)
    getMissions(state, Number(searchParams.get('page')) || 1).then(response => {
      setMissions(response.data?.data ?? [])
      setLastPage(response.data?.metadata?.lastPage ?? 1)
      setLoading(false)
    })
  }, [searchParams, state])


  // Handler for creating a new mission
  const handleNewMission = useCallback(() => {
    createMission().then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:MissionNotCreated'))
        return
      }

      navigate(getPaths.brands.wizard.step_1(response.data.id))
    })
  }, [i18n, navigate])


  // Handler for clicking on a mission card
  const missionCardHandler = useCallback((mission: MissionTypeAPI) => {
    mission.status === 'created'
      ? navigate(getPaths.brands.mission.details(mission.id))
      : navigate(getPaths.brands.wizard[mission.status](mission.id))
  }, [navigate])


  return (
    <div className={styles.missions}>
      <div className={styles.top}>
        <h1>
          {i18n.t('Common:Missions')}
        </h1>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {hasPermissions('mission_creator') && (
          <Button
            title={i18n.t('Mission:NewMission')}
            handler={handleNewMission}
          />
        )}
      </div>
      <TabIndicator
        currentTab={state}
        tabsLeft={[
          {
            id: 'ongoing',
            title: i18n.t('Mission:Ongoing'),
            onClick: () => navigate(getPaths.brands.missions.ongoing)
          },
          {
            id: 'before_start',
            title: i18n.t('Mission:BeforeStart'),
            onClick: () => navigate(getPaths.brands.missions.before_start)
          },
          {
            id: 'completed',
            title: i18n.t('Mission:Completed'),
            onClick: () => navigate(getPaths.brands.missions.completed)
          }
        ]}
        tabsRight={[
          {
            id: 'in_the_making',
            title: i18n.t('Mission:InTheMaking'),
            onClick: () => navigate(getPaths.brands.missions.in_the_making)
          }
        ]}
      />
      {loading ? (
        <Loading />
      ) : (
        missions.length === 0 ? (
          <div className={styles.noMissions}>
            <h3>
              {i18n.t('Mission:NoMissions')}
            </h3>
          </div>
        ) : (
          <>
            <div className={styles.missionsList}>
              {missions.map((mission, index) => (
                <MissionCard
                  key={`missionCard_${mission.id}`}
                  mission={mission}
                  grey={index % 2 === 0}
                  handler={() => missionCardHandler(mission)}
                />
              ))}
            </div>
            <PageIndicator
              currentPage={Number(searchParams.get('page')) || 1}
              lastPage={lastPage}
              isSearchParam
            />
          </>
        )
      )}
    </div>
  )
}
