import React, { ReactNode, useState } from 'react'

import styles from './ListCard.module.scss'

type MissionCardProps = {
  children: ReactNode
  handler: (() => void)
  grey?: boolean
  box?: boolean
}
 
export const ListCard = ({
  children,
  handler,
  grey,
  box
}: MissionCardProps) => {
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)

  return (
    <div
      className={`
        ${styles.listCard}
        ${grey && styles.grey}
        ${box && styles.box}
        ${hover && styles.hover}
        ${focus && styles.focus}
      `}
      onClick={handler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => { setHover(false); setFocus(false) }}
      onMouseDown={() => setFocus(true)}
      onMouseUp={() => setFocus(false)}
    >
      {children}
    </div>
  )
}
