import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswersStatusType, getAnswers } from '../../api'
import { QuestionTypeAPI, StoreAnswersHistoryTypeAPI } from '../../api/types'
import { AnswersConsts, AnswersStatusesType } from '../../Constants'
import { Dropdown } from '../../elements/Dropdown'
import { PageIndicator } from '../../elements/PageIndicator'
import { TextInput } from '../../elements/TextInput'
import useSearchParams, { searchParamsType } from '../../utils/useSearchParams'
import { Loading } from '../shared/Loading'
import { StoreAnswersHistoryCard } from '../StoreAnswersHistoryCard'

import styles from './MissionAnswers.module.scss'


const { ANSWERS_STATUSES } = AnswersConsts

type MissionAnswersProps = {
  missionId: number
  questions: QuestionTypeAPI[]
  marginLeftButtons: number
}


export const MissionAnswers = ({
  missionId,
  questions
}: MissionAnswersProps) => {
  const i18n = useTranslation()
  const { getSearchParams, searchParams, updateSearchParams, setSearchParams } = useSearchParams()

  const [loading, setLoading] = useState(true)
  const [storesAnswersHistory, setStoresAnswersHistory] = useState<StoreAnswersHistoryTypeAPI[]>([])
  const [lastPage, setLastPage] = useState(1)
  const [storeFilterText, setStoreFilterText] = useState(searchParams.get('store') || '')


  // Get the answers for the current search params
  // Fix posible bad values in the search params
  const handleGetAnswers = useCallback(() => {
    setLoading(true)

    // eslint-disable-next-line i18next/no-literal-string
    const status = (searchParams.get('status') as AnswersStatusType | null) || 'all'
    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1
    const store = searchParams.get('store')

    const paramsToFix = {} as searchParamsType
    // eslint-disable-next-line i18next/no-literal-string
    if (!Object.keys(ANSWERS_STATUSES).includes(status)) paramsToFix['status'] = 'all'
    if (page < 1 || isNaN(page)) paramsToFix['page'] = '1'
    else if (page > lastPage) paramsToFix['page'] = lastPage.toString()

    // URL search params are updated if there are bad values
    // and page will be rerendered and the function is called again
    if (Object.keys(paramsToFix).length > 0) {
      updateSearchParams(paramsToFix)
      return
    }

    getAnswers(missionId, status, page, store).then(response => {
      setStoresAnswersHistory(response.data?.data ?? [])
      setLastPage(response.data?.metadata?.lastPage ?? 1)
      setLoading(false)
    })
  }, [missionId, searchParams, lastPage, updateSearchParams])


  // Get the answers when the search params change
  useEffect(() => {
    handleGetAnswers()
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [searchParams]) // Only run when searchParams change


  // Handle the store filter debounce
  const handleDebounceStoreFilter = useCallback((v: string) => {
    if (v === '') {
      const newSearchParams = getSearchParams()
      newSearchParams.delete('store')
      newSearchParams.set('page', '1')
      setSearchParams(newSearchParams)
    } else {
      updateSearchParams({ store: v, page: '1' })
    }
  }, [updateSearchParams, setSearchParams, getSearchParams])


  return (
    <div>
      <div className={styles.filters}>
        <TextInput
          value={storeFilterText}
          placeholder={i18n.t('MissionWizard:WriteHereStoreName')}
          disabled={loading}
          onChange={setStoreFilterText}
          debounceHandler={handleDebounceStoreFilter}
          pill
          search
          customWidth='25rem'
        />
        <Dropdown<AnswersStatusesType>
          options={Object.values(ANSWERS_STATUSES)}
          selected={ANSWERS_STATUSES[searchParams.get('status') as keyof typeof ANSWERS_STATUSES] || ANSWERS_STATUSES.all}
          getOptionLabel={opt => opt.label}
          getOptionId={opt => opt.id}
          onChange={v => updateSearchParams({ status: v.id, page: '1' })}
          pill
          customWidth='15rem'
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        storesAnswersHistory.length === 0 ? (
          <div className={styles.noAnswers}>
            <h3>
              {i18n.t('Answers:NoAnswers')}
            </h3>
          </div>
        ) : (
          <>
            <div className={styles.missionsList}>
              {storesAnswersHistory.map(storeAnswersHistory => (
                <StoreAnswersHistoryCard
                  key={`answerCard_${storeAnswersHistory[0].storeName}_mission_${missionId}`}
                  storeAnswersHistory={storeAnswersHistory}
                  questions={questions}
                />
              ))}
            </div>
            <PageIndicator
              currentPage={Number(searchParams.get('page')) || 1}
              lastPage={lastPage}
              isSearchParam
            />
          </>
        )
      )}
    </div>
  )
}
