import { createTheme, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Footer } from './components/shared/Footer'
import { Header } from './components/shared/Header'
import { Loading } from './components/shared/Loading'
import { UserProvider, useUser } from './contexts/User'
import AuthRouter from './routing/Auth'
import AppRouter from './routing/index'

import 'react-toastify/dist/ReactToastify.css'
import './styles/global.scss'

import './localization'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const theme = createTheme({
  typography: {
    // eslint-disable-next-line i18next/no-literal-string
    fontFamily: '"Space Grotesk", sans-serif'
  }
})


const AppWithContexts = () => {
  const { isLoggedIn, loading } = useUser()

  return (
    <div className='page'>
      <Header />
      <div className='content'>
        {loading ? (
          <Loading />
        ) : (isLoggedIn ? (
            <AppRouter />
          ) : (
            <AuthRouter />
          )
        )}
      </div>
      <Footer />
    </div>
  )
}


root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ToastContainer />
          <UserProvider>
            <AppWithContexts />
          </UserProvider>
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  </LocalizationProvider>
)
